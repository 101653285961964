//$primary: #E02E26;
$primary: #ed3237;
$secondary: #4E5A62;
$info: #0095D4;

@import "../../node_modules/bootstrap/scss/bootstrap";

$fa-font-path: "../../fonts";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";

//font-family: 'Encode Sans Condensed', sans-serif;
//font-family: 'Open Sans', sans-serif;
$fontFamilySecondary: 'Roboto', sans-serif;

$headerHeight: 45px;

body {
  font-family: $fontFamilySecondary;
  color: #222222;
}

body::-webkit-scrollbar {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Encode Sans Condensed', sans-serif;
  color: $secondary;
}

section {
  width: 100%;
  padding: 2rem 0;
  background-color: #FFFFFF;
}

.chromeBottomPaddingLanding {
  padding-bottom: 75px !important;
}

.whiteBg {
  background-color: #FFFFFF !important;
}

.sectionGrey {
  background-color: #F5F5F5;
  box-shadow: inset 0 0 10px -6px rgba(0,0,0,0.6);
}

.sectionTitleBox {
  margin-bottom: 2rem;
}
.sectionTitle {
  font-family: 'Encode Sans Condensed', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.5rem;

  .sectionTitleFirst {
    color: $primary;
    padding-right: 3px;
  }

  .sectionTitleSecond {
    color: $secondary;
    padding-left: 3px;
  }
}
.sectionTitleUnderline {
  transform: skew(-30deg);
  height: 5px;
  background-color: #333333;
}
.sectionTitleUnderlineTwo {
  background-color: $primary;
}

.sectionHeadline {
  font-family: 'Encode Sans Condensed', sans-serif;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 40px;
  color: $secondary;
}

.subsectionTitle {
  text-align: center;
  font-weight: 700;
  margin: 2rem 0;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $primary;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/*
    Index Page
 */
#preLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  z-index: 9999;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  #companyLogoPreLoader {
    margin-bottom: 50px;
    max-width: 100%;
  }
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translate(-50%, -50%);
}

#heroBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  z-index: -200;

  background-image: url('../img/bg_50q.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 0 4px 10px -6px rgba(0,0,0,0.4);
  z-index: 300;
  background-color: rgba(255, 255, 255, 0.85);
}
.headerWithoutLogo {
  height: 50px;

  & > div {
    height: 50px;
  }
}

#companyLogoImg {
  height: 45px;
  margin: 0.5rem 0;
}

@include media-breakpoint-only(xs) {
  #companyLogo {
    text-align: center;
  }
  #companyLogoImg {
    margin-bottom: 0 !important;
  }
}

#primaryMenu {
  .primaryMenuItem {
    //height: $headerHeight;
    //padding-top: 10px;
    padding: 0.5rem;

    a {
      transition: color 0.3s ease-out;
      color: #333333;
      font-family: 'Encode Sans Condensed', sans-serif;
      font-weight: 600;
    }
    a:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}

#socialMenu {
  .socialMenuItem {
    padding: 0.3rem 0.5rem;

    a {
      transition: color 0.3s ease-out;
      color: #333333;
      font-size: 20px;
    }
    a:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

#landing {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
  padding-bottom: 3px;
  transition: padding-bottom 0.3s ease-out;

  #landingTagline {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
  }

  #scrollDown {
    text-align: center;
    margin-top: 10px;
  }
  .scrollDownIcon {
    font-size: 1rem;
    animation: scrollDownAnimation 1.5s infinite;
  }

  @include media-breakpoint-only(lg) {
    .scrollDownIcon {
      font-size: 1.5rem;
    }
    #landingTagline {
      font-size: 2.5rem;
    }
  }

  @include media-breakpoint-only(xl) {
    .scrollDownIcon {
      font-size: 1.5rem;
    }
    #landingTagline {
      font-size: 3rem;
    }
  }
}

.primaryServiceBox {
  background-color: #FFFFFF;
  padding: 2rem 1rem;
  text-align: center;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  margin-bottom: 1rem;
  //box-shadow: 0 0 20px 1px #AAAAAA;
  box-shadow: 0 0 10px -6px rgba(0,0,0,0.6);
  transition: all 0.3s ease-out;

  &:hover {
    box-shadow: 0 0 14px -6px rgba(0,0,0,0.6);
  }

  .primaryServiceTitle {
    color: $secondary;
    //text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
    margin: 1.5rem 0;
  }

  .primaryServiceIconBox {
    height: 150px;
    width: 100%;
  }
  .primaryServiceIcon {
    height: 150px;
    max-height: 150px;
    max-width: 100%;
  }

  .serviceBoxDivider {
    width: 50px;
    height: 5px;
    background-color: $primary;
    margin: 0.5rem auto;
    transform: skew(-30deg);
  }

  .secondaryServiceBox {
    text-align: left;
    margin-top: 2rem;
    height: 55px;
  }

  .secondaryServiceIconBox {
    text-align: center;
  }

  .secondaryServiceIcon {
    height: 55px;
    width: auto;
  }

  .secondaryServiceTitle {
    color: $secondary;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .secondaryServiceDescription {
    display: none;  // TO BE REMOVED
    font-size: 0.9rem;
  }
}

#aboutPieChart {
  max-width: 100%;
}
@include media-breakpoint-down(sm) {
  #aboutPieChart {
    margin-bottom: 2rem;
  }
}

#aboutStats {
  text-align: center;
  margin-bottom: 4rem;
  color: $secondary;

  .aboutStatsValue {
    color: $primary;
    font-weight: 700;
    margin-top: 1.5rem;
  }
}
@include media-breakpoint-down(sm) {
  #aboutStats {
    padding-right: 1rem;
  }
}

#aboutApproach {
  color: $secondary;
  text-align: center;
  margin-top: 2rem;

  p {
    margin: 1rem 0;
    padding: 1rem 0;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
  }

  .aboutApproachTitle {
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
  }

  .aboutApproachSubtitle {
    margin-top: 0;
  }
}

#team {
  #teamHeadline {
    font-size: 1.5rem;
  }

  .teamMemberBox {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0 0 10px -6px rgba(0,0,0,0.6);
    margin: 0 auto 1.5rem;
    max-width: 300px;

    img {
      //width: 100%;
      //max-height: 150px;
      height: 200px;
      width: auto;
      margin: auto;
      display: block;
    }

    .teamMemberContent {
      padding: 1.5rem;
    }

    .teamMemberName {
      color: $primary;
      font-weight: 700;
      font-size: 1.3rem;
      //text-align: center;
    }

    .teamMemberDesignation {
      font-weight: 600;
      font-size: 1.1rem;
      color: $secondary;
      //text-align: center;
    }

    .teamMemberDegree {
      font-size: 1.05rem;
      color: $secondary;
      //text-align: center;
    }

    .teamMemberDescription {
      font-size: 0.9rem;
      margin-top: 1.5rem;
      //text-align: justify;
    }
  }
}

#contact {
  #contactHeadline {
    margin: 2rem 1rem;
    font-size: 1.5rem;
  }
}
@include media-breakpoint-up(lg) {
  #contact {
    #contactHeadline {
      font-size: 2rem;
    }
  }
}

#testimonialsCarousel {
  i {
    color: $primary;
    font-size: 2rem;
  }

  .carousel-control-prev, .carousel-control-next {
    max-width: 40px;
  }
}

.testimonialBox {
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0 0 10px -6px rgba(0,0,0,0.6);
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 42px;
  text-align: center;

  .testimonialText {
    font-style: italic;
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }

  .testimonialText::before, .testimonialText::after {
    content: '"';
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 700;
  }
  .testimonialText::before {
    margin-right: 5px;
  }
  .testimonialText::after {
    margin-left: 5px;
  }

  .testimonialPersonName {
    color: $primary;
  }
}

#clients {

  .clientBox {
    text-align: center;
  }

  .clientLogo {
    width: 100%;
    max-width: 150px;
  }

  .clientName {
    font-size: 1rem;
    font-weight: 700;
    margin: 1rem 0;
    color: $secondary;
  }
}

@include media-breakpoint-only(xs) {
  #clients {
    .clientBox {
      margin-bottom: 0.5rem;
    }

    .clientNameBox {
      display: flex;
      align-items: center;
    }

    .clientName {
      text-align: left;
      margin: 0;
    }
  }
}

/*
  ANIMATIONS
 */
@keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
